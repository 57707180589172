import { css } from '@emotion/react';
import { FC, ReactNode } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { colors } from '../../theme/colors';

type Props = {
  type: string;
  title: ReactNode;
  description: string;
};

export const FeatureCard: FC<Props> = ({ type, title, description }) => {
  return (
    <div css={FeatureCardStyle}>
      {type === 'support' && (
        <StaticImage
          src='../../images/feature/support.svg'
          alt='豊富なサポート体制'
          objectFit='contain'
          quality={100}
          placeholder='none'
        />
      )}
      {type === 'numberLess' && (
        <StaticImage
          src='../../images/feature/number-less.svg'
          alt='ナンバーレス'
          objectFit='contain'
          quality={100}
          placeholder='none'
        />
      )}
      {type === 'icChip' && (
        <StaticImage
          src='../../images/feature/ic-chip.svg'
          alt='ICチップ搭載でタッチ決済対応'
          objectFit='contain'
          quality={100}
          placeholder='none'
        />
      )}
      {type === '3dSecure' && (
        <StaticImage
          src='../../images/feature/3d-secure.svg'
          alt='3Dセキュア対応'
          objectFit='contain'
          quality={100}
          placeholder='none'
        />
      )}
      {type === 'electronicBooks' && (
        <StaticImage
          src='../../images/feature/electronic-books.svg'
          alt='電子帳簿保存法に'
          objectFit='contain'
          quality={100}
          placeholder='none'
        />
      )}
      {type === 'realCard' && (
        <StaticImage
          src='../../images/feature/real-card.svg'
          alt='リアルカードが発行できる'
          objectFit='contain'
          quality={100}
          placeholder='none'
        />
      )}
      <p css={FeatureTitleStyle}>{title}</p>
      <p
        css={{ lineHeight: 2 }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

const FeatureCardStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 20,
  width: '100%',
  maxWidth: 320,

  '@media(max-width: 1119px)': {},

  '@media(max-width: 768px)': {},
});

const FeatureTitleStyle = css({
  fontWeight: 700,
  fontSize: 24,
  color: colors.primary,
  textAlign: 'center',
  minHeight: 72,
  display: 'flex',
  alignItems: 'center',

  '@media(max-width: 768px)': { fontSize: 22 },
});
