import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { SectionHeading } from '../atoms/SectionHeading';
import { FeatureCard } from './FeatureCard';

export const FeatureSection = forwardRef<HTMLElement>((_, ref) => {
  return (
    <section css={FeatureSectionStyle} ref={ref}>
      <SectionHeading maxWidth='100%'>paildの仕組みと特徴</SectionHeading>
      <StaticImage
        src='../../images/feature/feature.svg'
        alt='paildの仕組みと特徴'
        css={FeatureImageStylePC}
        quality={100}
        placeholder='none'
      />
      <StaticImage
        src='../../images/feature/feature-sp.svg'
        alt='paildの仕組みと特徴'
        css={FeatureImageStyleSP}
        quality={100}
        placeholder='none'
      />
      <div css={FeatureListStyle}>
        <FeatureCard
          type='support'
          title='豊富なサポート体制'
          description='専門チームが初期設定から導入定着まで伴走する有償サポートプランをご用意しました。<br /><a href="/request-document">詳しくはこちら</a>'
        />
        <FeatureCard
          type='realCard'
          title='2種類のカード発行が可能'
          description='リアルカードだけでなくバーチャルカードも発行可能なので、オンライン決済のためにカード到着を待つ必要はありません。'
        />
        <FeatureCard
          type='numberLess'
          title='ナンバーレス'
          description='リアルカードの券面や裏面にカード番号やセキュリティコードの表記なし。万が一紛失しても安心なデザインです。'
        />
        <FeatureCard
          type='icChip'
          title={
            <>
              ICチップ搭載で
              <br />
              タッチ決済対応
            </>
          }
          description='paildのリアルカードはICチップ搭載で、Visaのタッチ決済に対応している店舗でも利用できます。'
        />
        <FeatureCard
          type='3dSecure'
          title='3Dセキュア対応'
          description='不正利用を防ぐために求められる本人認証（3Dセキュア）が必要な決済にも対応しています。'
        />
        <FeatureCard
          type='electronicBooks'
          title={
            <>
              電子帳簿保存法
              <br />
              インボイス制度に対応
            </>
          }
          description='電子帳簿保存法対応のため、領収書の紙での保存が不要です。また、インボイス制度に必要な登録番号の読み取りや、仕訳の入力もサポート。 '
        />
      </div>
    </section>
  );
});

const FeatureSectionStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: 1120,
  padding: '0 17px',
  gap: 60,

  '@media(max-width: 1119px)': { gap: 30 },
});

const FeatureImageStylePC = css({
  display: 'block',

  '@media(max-width: 768px)': {
    display: 'none',
  },
});

const FeatureImageStyleSP = css({
  display: 'none',

  '@media(max-width: 768px)': {
    display: 'block',
  },
});

const FeatureListStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  rowGap: 60,
  columnGap: 60,
  flexWrap: 'wrap',

  '@media(max-width: 768px)': {
    rowGap: 40,
  },
});
