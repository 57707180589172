import { css } from '@emotion/react';
import { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { SectionHeading } from '../atoms/SectionHeading';
import { colors } from '../../theme/colors';

import cashingLimit from '../../images/function/cashing-limit.svg';
import cashingDetails from '../../images/function/cashing-details.svg';
import sassManagement from '../../images/function/sass-management.svg';
import tagManagement from '../../images/function/tag-management.svg';
import authoritySetting from '../../images/function/authority-setting.svg';
import review from '../../images/function/review.svg';
import limitNotification from '../../images/function/limit-notification.svg';
import evidenceManagement from '../../images/function/evidence-management.svg';
import autoLoading from '../../images/function/auto-loading.svg';
import automaticJournalEntry from '../../images/function/automatic-journal-entry.svg';
import linkAccountingApp from '../../images/function/link-accounting-app.svg';

export const FunctionSection = forwardRef<HTMLElement>((_, ref) => {
  const cashingLimitRef = useRef<HTMLDivElement>(null);
  const cashingDetailsRef = useRef<HTMLDivElement>(null);
  const sassManagementRef = useRef<HTMLDivElement>(null);
  const tagManagementRef = useRef<HTMLDivElement>(null);
  const authoritySettingRef = useRef<HTMLDivElement>(null);
  const reviewRef = useRef<HTMLDivElement>(null);
  const limitNotificationRef = useRef<HTMLDivElement>(null);
  const linkAccountingAppRef = useRef<HTMLDivElement>(null);
  const evidenceManagementRef = useRef<HTMLDivElement>(null);
  const autoLoadingRef = useRef<HTMLDivElement>(null);
  const automaticJournalEntryRef = useRef<HTMLDivElement>(null);

  const anchorScroll = (y: number) => {
    scrollBy({
      top: y - 84,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <section css={FunctionSectionStyle} ref={ref}>
      {/* StaticImageにpropsを渡せないので、コンポーネント化しないでハードにコーディング */}
      <div css={FunctionAnchorButtonWrapperStyle}>
        <StaticImage
          src='../../images/function/function-deco-1.svg'
          alt='background_function_1'
          quality={100}
          placeholder='none'
          css={{
            position: 'absolute',
            top: -30,
            left: 50,
            '@media(max-width: 1119px)': {
              width: 108,
              top: -20,
            },
            '@media(max-width: 768px)': {
              left: 20,
            },
          }}
        />
        <StaticImage
          src='../../images/function/function-deco-2.svg'
          alt='background_function_2'
          quality={100}
          placeholder='none'
          css={{
            position: 'absolute',
            bottom: -30,
            right: 50,

            '@media(max-width: 1119px)': {
              width: 108,
            },

            '@media(max-width: 768px)': {
              right: 24,
            },
          }}
        />
        <SectionHeading>機能一覧</SectionHeading>
        <div css={FunctionAnchorButtonListStyle}>
          <button
            css={FunctionAnchorButtonStyle}
            onClick={() => {
              if (!cashingLimitRef.current) return;
              anchorScroll(cashingLimitRef.current.getBoundingClientRect().y);
            }}
          >
            <img
              src={cashingLimit}
              alt='利用上限金額設定'
              placeholder='none'
              className='button-illust'
              css={ButtonIconStyle}
            />
            <p css={FunctionAnchorButtonTextStyle}>
              利用上限
              <br />
              金額設定
            </p>
            <StaticImage
              src='../../images/icons/arrow-bottom.svg'
              alt='↓'
              quality={100}
              placeholder='none'
              className='arrow-bottom'
            />
          </button>
          <button
            css={FunctionAnchorButtonStyle}
            onClick={() => {
              if (!cashingDetailsRef.current) return;
              anchorScroll(cashingDetailsRef.current.getBoundingClientRect().y);
            }}
          >
            <img
              src={cashingDetails}
              alt='利用履歴'
              placeholder='none'
              className='button-illust'
              css={ButtonIconStyle}
            />
            <p css={FunctionAnchorButtonTextStyle}>利用履歴</p>
            <StaticImage
              src='../../images/icons/arrow-bottom.svg'
              alt='↓'
              quality={100}
              placeholder='none'
              className='arrow-bottom'
            />
          </button>
          <button
            css={FunctionAnchorButtonStyle}
            onClick={() => {
              if (!sassManagementRef.current) return;
              anchorScroll(sassManagementRef.current.getBoundingClientRect().y);
            }}
          >
            <img
              src={sassManagement}
              alt='SaaS管理'
              placeholder='none'
              className='button-illust'
              css={ButtonIconStyle}
            />
            <p css={FunctionAnchorButtonTextStyle}>
              SaaS
              <br />
              管理
            </p>
            <StaticImage
              src='../../images/icons/arrow-bottom.svg'
              alt='↓'
              quality={100}
              placeholder='none'
              className='arrow-bottom'
            />
          </button>
          <button
            css={FunctionAnchorButtonStyle}
            onClick={() => {
              if (!tagManagementRef.current) return;
              anchorScroll(tagManagementRef.current.getBoundingClientRect().y);
            }}
          >
            <img
              src={tagManagement}
              alt='タグ管理'
              placeholder='none'
              className='button-illust'
              css={ButtonIconStyle}
            />
            <p css={FunctionAnchorButtonTextStyle}>タグ管理</p>
            <StaticImage
              src='../../images/icons/arrow-bottom.svg'
              alt='↓'
              quality={100}
              placeholder='none'
              className='arrow-bottom'
            />
          </button>
          <button
            css={FunctionAnchorButtonStyle}
            onClick={() => {
              if (!authoritySettingRef.current) return;
              anchorScroll(
                authoritySettingRef.current.getBoundingClientRect().y
              );
            }}
          >
            <img
              src={authoritySetting}
              alt='権限設定'
              placeholder='none'
              className='button-illust'
              css={ButtonIconStyle}
            />
            <p css={FunctionAnchorButtonTextStyle}>権限設定</p>
            <StaticImage
              src='../../images/icons/arrow-bottom.svg'
              alt='↓'
              quality={100}
              placeholder='none'
              className='arrow-bottom'
            />
          </button>
          <button
            css={FunctionAnchorButtonStyle}
            onClick={() => {
              if (!reviewRef.current) return;
              anchorScroll(reviewRef.current.getBoundingClientRect().y);
            }}
          >
            <img
              src={review}
              alt='レビュー'
              placeholder='none'
              className='button-illust'
              css={ButtonIconStyle}
            />
            <p css={FunctionAnchorButtonTextStyle}>レビュー</p>
            <StaticImage
              src='../../images/icons/arrow-bottom.svg'
              alt='↓'
              quality={100}
              placeholder='none'
              className='arrow-bottom'
            />
          </button>
          <button
            css={FunctionAnchorButtonStyle}
            onClick={() => {
              if (!limitNotificationRef.current) return;
              anchorScroll(
                limitNotificationRef.current.getBoundingClientRect().y
              );
            }}
          >
            <img
              src={limitNotification}
              alt='メール通知'
              placeholder='none'
              className='button-illust'
              css={ButtonIconStyle}
            />
            <p css={FunctionAnchorButtonTextStyle}>メール通知</p>
            <StaticImage
              src='../../images/icons/arrow-bottom.svg'
              alt='↓'
              quality={100}
              placeholder='none'
              className='arrow-bottom'
            />
          </button>

          <button
            css={FunctionAnchorButtonStyle}
            onClick={() => {
              if (!evidenceManagementRef.current) return;
              anchorScroll(
                evidenceManagementRef.current.getBoundingClientRect().y
              );
            }}
          >
            <img
              src={evidenceManagement}
              alt='領収書管理'
              placeholder='none'
              className='button-illust'
              css={ButtonIconStyle}
            />
            <p css={FunctionAnchorButtonTextStyle}>領収書管理</p>
            <StaticImage
              src='../../images/icons/arrow-bottom.svg'
              alt='↓'
              quality={100}
              placeholder='none'
              className='arrow-bottom'
            />
          </button>
          <button
            css={[FunctionAnchorButtonStyle, { gap: 11 }]}
            onClick={() => {
              if (!autoLoadingRef.current) return;
              anchorScroll(autoLoadingRef.current.getBoundingClientRect().y);
            }}
          >
            <img
              src={autoLoading}
              alt='自動読み取り'
              placeholder='none'
              className='button-illust'
              css={ButtonIconStyle}
            />
            <div>
              <p css={FunctionAnchorButtonTextStyle}>自動読み取り</p>
              <p css={{ fontSize: 12 }}>（AI-OCR）</p>
            </div>
            <StaticImage
              src='../../images/icons/arrow-bottom.svg'
              alt='↓'
              quality={100}
              placeholder='none'
              className='arrow-bottom'
            />
          </button>
          <button
            css={FunctionAnchorButtonStyle}
            onClick={() => {
              if (!automaticJournalEntryRef.current) return;
              anchorScroll(
                automaticJournalEntryRef.current.getBoundingClientRect().y
              );
            }}
          >
            <img
              src={automaticJournalEntry}
              alt='自動仕訳'
              placeholder='none'
              className='button-illust'
              css={ButtonIconStyle}
            />
            <p css={FunctionAnchorButtonTextStyle}>自動仕訳</p>
            <StaticImage
              src='../../images/icons/arrow-bottom.svg'
              alt='↓'
              quality={100}
              placeholder='none'
              className='arrow-bottom'
            />
          </button>
          <button
            css={FunctionAnchorButtonStyle}
            onClick={() => {
              if (!linkAccountingAppRef.current) return;
              anchorScroll(
                linkAccountingAppRef.current.getBoundingClientRect().y
              );
            }}
          >
            <img
              src={linkAccountingApp}
              alt='会計ソフト連携'
              placeholder='none'
              className='button-illust'
              css={ButtonIconStyle}
            />
            <p css={FunctionAnchorButtonTextStyle}>
              会計ソフト
              <br />
              連携
            </p>
            <StaticImage
              src='../../images/icons/arrow-bottom.svg'
              alt='↓'
              quality={100}
              placeholder='none'
              className='arrow-bottom'
            />
          </button>
        </div>
      </div>
      <div css={FunctionListStyle}>
        {/* 利用上限金額設定 */}
        <div css={FunctionWrapperStyle()} ref={cashingLimitRef}>
          <div css={FunctionWrapperInnerStyle()}>
            <div css={FunctionDescriptionWrapperStyle}>
              <h3 css={FunctionHeadingStyle}>
                <img
                  src={cashingLimit}
                  alt='利用上限金額設定'
                  placeholder='none'
                  css={IconStyle}
                />
                利用上限金額設定
              </h3>
              <p>
                カードごとに利用上限金額や期間を組み合わせて設定できるため、用途に合わせてカスタマイズ可能。また、設定の変更はすぐ反映されます。
              </p>
              <div css={FunctionImageWrapperStyleSP(true)}>
                <StaticImage
                  src='../../images/function/cashing-limit.png'
                  alt='利用上限金額設定'
                  quality={100}
                  placeholder='none'
                />
              </div>
              <ul css={MeritListStyle()}>
                <div css={MeritStyle}>メリット</div>
                <li>
                  誤使用のリスクを下げることができ、ガバナンス面で安心できる
                </li>
                <li>出張中など特定期間中のみ使えるカードも設定可能</li>
              </ul>
            </div>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/function/cashing-limit.png'
                alt='利用上限金額設定'
                quality={100}
                placeholder='none'
                css={[FunctionImageStyle(), { boxShadow: 'none' }]}
                objectFit='contain'
              />
            </div>
          </div>
        </div>

        {/* 利用履歴 */}
        <div css={FunctionWrapperStyle(true)} ref={cashingDetailsRef}>
          <div css={FunctionWrapperInnerStyle(true)}>
            <div css={FunctionDescriptionWrapperStyle}>
              <h3 css={FunctionHeadingStyle}>
                <img
                  src={cashingDetails}
                  alt='利用履歴'
                  placeholder='none'
                  css={IconStyle}
                />
                利用履歴
              </h3>
              <p>
                いつ、どこで、いくら決済したかリアルタイムで表示されます。領収書が提出されていない明細のみ絞り込むなど、フィルター機能を活用することで、確認時間が短縮されます。
              </p>
              <div css={FunctionImageWrapperStyleSP()}>
                <StaticImage
                  src='../../images/function/cashing-details.png'
                  alt='利用履歴'
                  quality={100}
                  placeholder='none'
                />
              </div>
              <ul css={MeritListStyle(true)}>
                <div css={MeritStyle}>メリット</div>
                <li>リアルタイムに正確な支出管理ができる</li>
                <li>確認の手間を削減</li>
              </ul>
            </div>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/function/cashing-details.png'
                alt='利用履歴'
                quality={100}
                placeholder='none'
                css={FunctionImageStyle(true)}
              />
            </div>
          </div>
        </div>

        {/* SaaS管理 */}
        <div css={FunctionWrapperStyle()} ref={sassManagementRef}>
          <div css={FunctionWrapperInnerStyle()}>
            <div css={FunctionDescriptionWrapperStyle}>
              <h3 css={FunctionHeadingStyle}>
                <img
                  src={sassManagement}
                  alt='SaaS管理'
                  placeholder='none'
                  css={IconStyle}
                />
                SaaS管理
              </h3>
              <p>
                利用しているサービスやツールを一覧で把握できます。またカードごとに専用メールアドレスが発行されるため、領収書が添付されたメールの転送先として設定しておくだけで、電子帳簿保存法に準拠した形で領収書が自動で保存されます。
              </p>
              <div css={FunctionImageWrapperStyleSP()}>
                <StaticImage
                  src='../../images/function/sass-management.png'
                  alt='SaaS管理'
                  quality={100}
                  placeholder='none'
                />
              </div>
              <ul css={MeritListStyle()}>
                <div css={MeritStyle}>メリット</div>
                <li>使用してないのに支払い続けているサービスを見つけられる</li>
                <li>
                  領収書が自動で取り込まれるので、提出・回収の手間を省ける
                </li>
              </ul>
            </div>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/function/sass-management.png'
                alt='SaaS管理'
                quality={100}
                placeholder='none'
                css={FunctionImageStyle()}
              />
            </div>
          </div>
        </div>

        {/* タグ管理 */}
        <div css={FunctionWrapperStyle(true)} ref={tagManagementRef}>
          <div css={FunctionWrapperInnerStyle(true)}>
            <div css={FunctionDescriptionWrapperStyle}>
              <h3 css={FunctionHeadingStyle}>
                <img
                  src={tagManagement}
                  alt='タグ管理'
                  placeholder='none'
                  css={IconStyle}
                />
                タグ管理
              </h3>
              <p>
                カード枚数が多い場合、カードにタグをつけると管理しやすくなります。タグの文字は自由に設定でき、タグを用いた絞り込みが可能です。また、CSV出力や会計ソフトにも反映されます。
              </p>
              <div css={FunctionImageWrapperStyleSP(true)}>
                <StaticImage
                  src='../../images/function/tag-management.png'
                  alt='タグ管理'
                  quality={100}
                  placeholder='none'
                />
              </div>
              <ul css={MeritListStyle(true)}>
                <div css={MeritStyle}>メリット</div>
                <li>複数カードの管理がしやすい</li>
              </ul>
            </div>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/function/tag-management.png'
                alt='タグ管理'
                quality={100}
                placeholder='none'
                css={[FunctionImageStyle(true), { boxShadow: 'none' }]}
              />
            </div>
          </div>
        </div>

        {/* 権限設定 */}
        <div css={FunctionWrapperStyle()} ref={authoritySettingRef}>
          <div css={FunctionWrapperInnerStyle()}>
            <div css={FunctionDescriptionWrapperStyle}>
              <h3 css={FunctionHeadingStyle}>
                <img
                  src={authoritySetting}
                  alt='権限設定'
                  placeholder='none'
                  css={IconStyle}
                />
                権限設定
              </h3>
              <p>
                権限は4種類あり（取引、利用、レビュー、管理）それらを組み合わせてメンバーに割り当てることができます。
                <a
                  href='https://support.paild.jp/hc/ja/articles/360052745893-%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E6%A8%A9%E9%99%90%E3%81%AE%E7%A8%AE%E9%A1%9E%E3%81%A8-%E3%81%A7%E3%81%8D%E3%82%8B%E3%81%93%E3%81%A8%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84'
                  target='_blank'
                  rel='noreferrer'
                  css={LinkStyle}
                >
                  権限組み合わせの詳細はこちら
                </a>
              </p>
              <div css={FunctionImageWrapperStyleSP()}>
                <StaticImage
                  src='../../images/function/authority-setting.png'
                  alt='権限設定'
                  quality={100}
                  placeholder='none'
                />
              </div>
              <ul css={MeritListStyle()}>
                <div css={MeritStyle}>メリット</div>
                <li>従業員に安心して配布できる</li>
                <li>
                  カード発行する権限と、カード番号を見る権限を分けることができる
                </li>
              </ul>
            </div>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/function/authority-setting.png'
                alt='権限設定'
                quality={100}
                placeholder='none'
                css={FunctionImageStyle()}
              />
            </div>
          </div>
        </div>

        {/* レビュー */}
        <div css={FunctionWrapperStyle(true)} ref={reviewRef}>
          <div css={FunctionWrapperInnerStyle(true)}>
            <div css={FunctionDescriptionWrapperStyle}>
              <h3 css={FunctionHeadingStyle}>
                <img
                  src={review}
                  alt='レビュー'
                  placeholder='none'
                  css={IconStyle}
                />
                レビュー
              </h3>
              <p>
                部門や店舗の管理者、経理の方に向けた、利用明細を確認しレビューする機能です。誰がいつレビューしたかの記録が残り、事後承認に活用できます。
              </p>
              <div css={FunctionImageWrapperStyleSP()}>
                <StaticImage
                  src='../../images/function/cashing-details.png'
                  alt='レビュー'
                  quality={100}
                  placeholder='none'
                />
              </div>
              <ul css={MeritListStyle(true)}>
                <div css={MeritStyle}>メリット</div>
                <li>レビュー範囲はタグによって自由に制御することができる</li>
                <li>複数人による承認フローの運用ができる</li>
              </ul>
            </div>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/function/review.png'
                alt='レビュー'
                quality={100}
                placeholder='none'
                css={FunctionImageStyle(true)}
              />
            </div>
          </div>
        </div>

        {/* メール通知 */}
        <div css={FunctionWrapperStyle()} ref={limitNotificationRef}>
          <div css={FunctionWrapperInnerStyle()}>
            <div css={FunctionDescriptionWrapperStyle}>
              <h3 css={FunctionHeadingStyle}>
                <img
                  src={limitNotification}
                  alt='メール通知'
                  placeholder='none'
                  css={IconStyle}
                />
                メール通知
              </h3>
              <p>
                入金漏れを防ぐため、利用可能額が一定額を下回った際に通知することが可能です。また、各カードが決済されるたびに、リアルタイムで管理者にメール通知がされるため、すばやく不正検知できます。
              </p>
              <div css={FunctionImageWrapperStyleSP()}>
                <StaticImage
                  src='../../images/function/limit-notification.png'
                  alt='メール通知'
                  quality={100}
                  placeholder='none'
                />
              </div>
              <ul css={MeritListStyle()}>
                <div css={MeritStyle}>メリット</div>
                <li>入金のタイミングがわかりやすい</li>
                <li>決済してすぐ不正検知できる</li>
              </ul>
            </div>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/function/limit-notification.png'
                alt='管理者向けのメール通知機能'
                quality={100}
                placeholder='none'
                css={FunctionImageStyle()}
              />
            </div>
          </div>
        </div>

        {/* 領収書管理 */}
        <div css={FunctionWrapperStyle(true)} ref={evidenceManagementRef}>
          <div css={FunctionWrapperInnerStyle(true)}>
            <div css={FunctionDescriptionWrapperStyle}>
              <h3 css={FunctionHeadingStyle}>
                <img
                  src={evidenceManagement}
                  alt='領収書管理'
                  placeholder='none'
                  css={IconStyle}
                />
                領収書管理
              </h3>
              <p>
                カード決済後すぐに領収書提出依頼メールが利用者に届き、メールに領収書の画像を添付するだけで簡単にアップロードできます。
              </p>
              <div css={FunctionImageWrapperStyleSP()}>
                <StaticImage
                  src='../../images/function/evidence-management.png'
                  alt='領収書管理'
                  quality={100}
                  placeholder='none'
                />
              </div>
              <ul css={MeritListStyle(true)}>
                <div css={MeritStyle}>メリット</div>
                <li>領収書の提出・管理が簡単</li>
                <li>月末月初の業務の偏りを減らせる</li>
              </ul>
            </div>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/function/evidence-management.png'
                alt='領収書管理'
                quality={100}
                placeholder='none'
                objectFit='contain'
                css={[
                  FunctionImageStyle(true),
                  { width: 'fit-content', background: colors.white },
                ]}
              />
            </div>
          </div>
        </div>

        {/* AI-OCR */}
        <div css={FunctionWrapperStyle()} ref={autoLoadingRef}>
          <div css={FunctionWrapperInnerStyle()}>
            <div css={FunctionDescriptionWrapperStyle}>
              <h3 css={FunctionHeadingStyle}>
                <img
                  src={autoLoading}
                  alt='AI-OCR'
                  placeholder='none'
                  css={IconStyle}
                />
                <span css={{ display: 'block', textAlign: 'center' }}>
                  AI-OCR
                </span>
              </h3>
              <p>
                領収書に記載の情報を自動で読み取り、カードの決済データ（日付・金額）と一致しているかチェックします。さらに、適格事業者番号を国税庁のデータベースと自動照合。不備がある場合は、管理画面にアラートが表示されるため、確認業務の工数を削減できます。
              </p>
              <div css={FunctionImageWrapperStyleSP()}>
                <StaticImage
                  src='../../images/function/auto-input.png'
                  alt='AI-OCR'
                  quality={100}
                  placeholder='none'
                />
              </div>
              <ul css={MeritListStyle()}>
                <div css={MeritStyle}>メリット</div>
                <li>誤った領収書を自動検知</li>
                <li>インボイス制度に対応するための手入力・照合作業が不要に</li>
              </ul>
            </div>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/function/auto-input.png'
                alt='AI-OCR'
                quality={100}
                placeholder='none'
                css={FunctionImageStyle()}
              />
            </div>
          </div>
        </div>

        {/* 自動仕訳 */}
        <div css={FunctionWrapperStyle(true)} ref={automaticJournalEntryRef}>
          <div css={FunctionWrapperInnerStyle(true)}>
            <div css={FunctionDescriptionWrapperStyle}>
              <h3 css={FunctionHeadingStyle}>
                <img
                  src={automaticJournalEntry}
                  alt='自動仕訳'
                  placeholder='none'
                  css={IconStyle}
                />
                自動仕訳
              </h3>
              <p>
                決済データはリアルタイムで管理画面に反映されます。利用者が費目を入力すると、あらかじめ設定した勘定科目、税区分が自動入力されます。また、領収書と明細情報が一つの画面で表示されるため、確認がスムーズに行えます。
              </p>
              <div css={FunctionImageWrapperStyleSP(true)}>
                <StaticImage
                  src='../../images/function/automatic-journal-entry.png'
                  alt='自動仕訳'
                  quality={100}
                  placeholder='none'
                />
              </div>
              <ul css={MeritListStyle(true)}>
                <div css={MeritStyle}>メリット</div>
                <li>会計処理に必要な情報の収集・入力までスムーズ</li>
                <li>入力ミスの防止</li>
              </ul>
            </div>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/function/automatic-journal-entry.png'
                alt='自動仕訳'
                quality={100}
                placeholder='none'
                css={FunctionImageStyle(true, true)}
              />
            </div>
          </div>
        </div>

        {/* 会計ソフト連携 */}
        <div css={FunctionWrapperStyle()} ref={linkAccountingAppRef}>
          <div css={FunctionWrapperInnerStyle()}>
            <div css={FunctionDescriptionWrapperStyle}>
              <h3 css={FunctionHeadingStyle}>
                <img
                  src={linkAccountingApp}
                  alt='会計ソフト連携'
                  placeholder='none'
                  css={IconStyle}
                />
                会計ソフト連携
              </h3>
              <p>
                freee会計、MFクラウド会計をご利用の場合は自動API連携が可能です。その他の会計ソフトをご利用の場合はCSVインポートで連携できます。CSVについては、自由にフォーマットをカスタマイズ可能なため、どの会計ソフトでも利用が可能です。
              </p>
              <div css={FunctionImageWrapperStyleSP()}>
                <StaticImage
                  src='../../images/function/link-accounting-app.png'
                  alt='会計ソフト連携'
                  quality={100}
                  placeholder='none'
                />
              </div>
              <ul css={MeritListStyle()}>
                <div css={MeritStyle}>メリット</div>
                <li>どの会計ソフトでも、スムーズな連携が可能</li>
              </ul>
            </div>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/function/link-accounting-app.png'
                alt='会計ソフト連携'
                quality={100}
                placeholder='none'
                css={FunctionImageStyle()}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

const FunctionSectionStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',

  '@media(max-width: 1119px)': { gap: 30 },
});

const FunctionAnchorButtonWrapperStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: colors.secondary,
  padding: '80px 0 20px',
  position: 'relative',

  '@media(max-width: 1119px)': {
    padding: '40px 0 20px',
  },
});

const FunctionAnchorButtonListStyle = css({
  display: 'grid',
  padding: '60px 17px',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: 24,
  width: '100%',
  maxWidth: 1120,
  zIndex: 5,

  '@media(max-width: 1119px)': {
    gap: 10,
    maxWidth: 720,
    padding: '30px 17px',
  },

  '@media(max-width: 768px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
});

const FunctionAnchorButtonStyle = css({
  padding: 20,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 20,
  borderRadius: 16,
  boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.16)',
  transition: 'color 0.3s',
  background: colors.white,

  br: { display: 'none' },

  '@media(max-width: 1119px)': {
    gap: 12,

    br: { display: 'inline' },
  },

  '@media(max-width: 768px)': {
    gap: 6,
    padding: 12,
  },

  '& .arrow-bottom': {
    top: 0,
    transition: '0.3s',
  },

  '&:hover': {
    color: colors.primary,
    '& .arrow-bottom': {
      top: 8,
    },
  },
});

const ButtonIconStyle = css({
  width: '100%',
  height: 70,

  '@media(max-width: 1119px)': {
    width: 84,
  },

  '@media(max-width: 768px)': {
    width: 72,
  },
});

const FunctionAnchorButtonTextStyle = css({
  fontSize: 18,
  fontWeight: 700,

  '@media(max-width: 1119px)': {
    fontSize: 14,
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
  },

  '@media(max-width: 768px)': {
    fontSize: 12,
    minHeight: 36,
  },
});

const FunctionWrapperStyle = (isAlt?: boolean) =>
  css({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: isAlt ? colors.secondary : colors.white,
    overflow: 'hidden',
  });

const FunctionListStyle = css({
  width: '100%',
});

const FunctionWrapperInnerStyle = (isAlt?: boolean) =>
  css({
    width: '100%',
    maxWidth: 1420,
    padding: '100px 150px',
    display: 'flex',
    gap: '4%',
    flexDirection: isAlt ? 'row-reverse' : 'row',

    '@media(max-width: 1119px)': {
      maxWidth: 720,
      padding: '50px 17px',
      gap: 30,
    },

    '@media(max-width: 768px)': {
      padding: '40px 17px',
    },
  });

const FunctionDescriptionWrapperStyle = css({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 40,

  p: {
    lineHeight: 2,
  },
});

const FunctionHeadingStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  fontSize: 40,
  color: colors.primary,

  '@media(max-width: 1119px)': {
    fontSize: 28,
  },
});

const IconStyle = css({
  width: 100,
  minWidth: 57,

  '@media(max-width: 1119px)': {
    width: 84,
  },
});

const FunctionImageWrapperStyle = css({
  minWidth: '50%',
  position: 'relative',

  '@media(max-width: 768px)': {
    display: 'none',
  },
});

const FunctionImageStyle = (isAlt?: boolean, isNoneShadow?: boolean) =>
  css({
    width: 'calc(100% + 120px)',
    position: 'absolute',
    left: isAlt ? 'auto' : 0,
    right: isAlt ? 0 : 'auto',
    transform: 'translate(0, -50%)',
    top: '50%',
    maxHeight: 460,
    boxShadow: isNoneShadow ? 'none' : '0px 1.8px 8.1px 0px #00000040',

    '@media(max-width: 1119px)': {
      width: '100%',
      transform: 'translate(0, 0)',
      top: 0,
      maxHeight: 320,
    },
  });

const FunctionImageWrapperStyleSP = (isNoneShadow?: boolean) =>
  css({
    display: 'none',
    width: '100%',
    boxShadow: isNoneShadow ? 'none' : '0px 1.8px 8.1px 0px #00000040',

    '@media(max-width: 768px)': {
      display: 'block',
    },
  });

const MeritListStyle = (isAlt?: boolean) =>
  css({
    position: 'relative',
    padding: '30px 24px 30px 36px',
    borderRadius: 16,
    background: isAlt ? colors.white : colors.secondary,

    li: {
      '&::marker': {
        color: colors.primary,
      },
    },
  });

const MeritStyle = css({
  position: 'absolute',
  top: -15,
  left: 24,

  padding: '8px 20px',
  color: colors.white,
  background: colors.primary,
  borderRadius: 50,
  lineHeight: 1,
});

const LinkStyle = css({
  color: colors.primary,
  textDecoration: 'underline',
  fontWeight: 500,

  '&:hover': {
    color: colors.black,
  },
});
