import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { SectionHeading } from '../atoms/SectionHeading';
import { colors } from '../../theme/colors';
import CardAmountVideo from '../../images/function/issue/card-amount.mp4';

export const IssueSection = forwardRef<HTMLElement>((_, ref) => {
  return (
    <section css={IssueSectionStyle} ref={ref}>
      <SectionHeading maxWidth='100%'>カード発行について</SectionHeading>
      <div css={IssueWrapperStyle}>
        <div css={CardIssueStyle}>
          <h3 css={CardIssueHeadingStyle}>
            <span>何枚でも</span>カンタンに
            <br />
            カード発行できる
          </h3>
          <p css={CardIssueDescriptionStyle}>
            煩雑な書類記入や与信審査はありません。
            <br />
            カードは管理画面から最短10秒でバーチャルカードを発行でき、すぐにオンライン決済で使えます。実店舗で使う場合は管理画面からリアルカードを申請すると約5～10営業日程度でお手元に届きます。
            <br />
            ※リアルカード発行手数料は30枚まで無料、それ以降は1枚1,400円（税抜）となります
            <br />
            ※各種パッケージプランご利用の場合は31枚以上も発行手数料は無料です
          </p>
        </div>
        <div css={CardIssueFlowGifStyle}>
          <video
            controls
            autoPlay
            muted
            loop
            playsInline
            css={{ width: '100%' }}
          >
            <source src={CardAmountVideo} type='video/mp4' />
          </video>
        </div>
      </div>
      <div css={IssueFeatureWrapperStyle}>
        <div css={IssueFeatureStyle}>
          <StaticImage
            src='../../images/function/issue/card-name.png'
            alt='カード名を自由に設定できる'
            quality={100}
            placeholder='none'
            objectFit='contain'
            css={IssueFeatureImageStyle}
          />
          <p>
            カード名は自由に設定・変更できます。用途や店舗、個人名などをつけることで複数のカードを管理しやすくなります。
          </p>
        </div>
        <div
          css={[
            IssueFeatureStyle,
            { '@media(max-width: 768px)': { flexDirection: 'row-reverse' } },
          ]}
        >
          <StaticImage
            src='../../images/function/issue/card-stop.png'
            alt='ワンクリックでカードを停止できる'
            quality={100}
            placeholder='none'
            objectFit='contain'
            css={IssueFeatureImageStyle}
          />
          <p>
            カードの一時停止は管理画面から即対応できます。カード会社への連絡は不要です。管理権限のあるメンバーが停止や再開の設定を行います。
          </p>
        </div>
      </div>
    </section>
  );
});

const IssueSectionStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: 1120,
  padding: '0 17px',
  gap: 60,

  '@media(max-width: 1119px)': {
    gap: 30,
  },
});

const IssueWrapperStyle = css({
  width: '100%',
  display: 'flex',
  gap: 40,

  '@media(max-width: 768px)': {
    flexDirection: 'column-reverse',
    gap: 30,
  },
});

const CardIssueStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: '35%',
  gap: 20,

  '@media(max-width: 768px)': {
    width: '100%',
    paddingRight: 0,
    alignItems: 'center',
  },
});

const CardIssueHeadingStyle = css({
  fontSize: 40,
  textAlign: 'center',

  span: { color: colors.primary },

  '@media(max-width: 1119px)': {
    fontSize: 28,
    textAlign: 'left',
  },
});

const CardIssueDescriptionStyle = css({
  fontSize: 16,
  lineHeight: 2,

  '@media(max-width: 1119px)': {
    fontSize: 14,
  },
});

const CardIssueFlowGifStyle = css({
  alignSelf: 'center',
  width: '65%',
  height: 'fit-content',
  overflow: 'hidden',
  borderRadius: 16,
  boxShadow: '0px 1.8px 8.1px 0px #00000040',
  objectPosition: 'center',

  '@media(max-width: 768px)': {
    width: '100%',
  },
});

const IssueFeatureWrapperStyle = css({
  display: 'flex',
  gap: 40,

  '@media(max-width: 768px)': {
    flexDirection: 'column',
  },
});

const IssueFeatureStyle = css({
  flex: 1,
  display: 'flex',
  gap: 30,
  alignItems: 'center',

  p: {
    lineHeight: 2,
  },
});

const IssueFeatureImageStyle = css({
  flexGrow: 1,
  borderRadius: 16,
  boxShadow: '0px 1.8px 8.1px 0px #00000040',

  img: {
    borderRadius: 16,
  },

  '@media(max-width: 1119px)': {
    maxWidth: 160,
    minWidth: 160,
    height: 160,
  },
});
