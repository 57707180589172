import { css } from '@emotion/react';
import { useRef } from 'react';

import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';
import { ArticleListHeading } from '../../components/organisms/ArticleListHeading';
import { FeatureSection } from '../../components/feature/FeatureSection';
import { IssueSection } from '../../components/feature/IssueSection';
import { FunctionSection } from '../../components/feature/FunctionSection';
import { PaildButton } from '../../components/atoms/PaildButton';

export const Head = () => (
  <HeadInner
    path='/feature/'
    title='機能'
    description='paild（ペイルド）の機能です。カード発行、利用制限、通知機能、AI-OCR、領収書管理、仕訳、会計ソフト連携、電子帳簿保存法、インボイス制度、豊富なサポート体制'
  />
);

const FeaturePage = () => {
  const featureSectionRef = useRef<HTMLElement>(null);
  const issueSectionRef = useRef<HTMLElement>(null);
  const functionSectionRef = useRef<HTMLElement>(null);

  const anchorScroll = (y: number) => {
    scrollBy({
      top: innerWidth < 1120 ? y - 84 : y - 168,
      left: 0,
      behavior: 'smooth',
    });
  };

  const links = [{ name: 'paildの機能', path: '/feature/' }];

  return (
    <Layout links={links}>
      <ArticleListHeading
        category='feature'
        title='paildの機能'
        description={[
          '法人支出の最適化を実現する「paild」の仕組みと主な機能を紹介します。',
          '従業員に安心して配布でき、支出管理をスムーズにするための機能を搭載しています。',
          '今後も、ご利用企業様の声をもとに新機能を順次開発していきます。',
        ]}
      />
      <section css={AnchorButtonSection}>
        <PaildButton
          outlined
          isAnchor
          onClick={() => {
            if (!featureSectionRef.current) return;
            anchorScroll(featureSectionRef.current.getBoundingClientRect().y);
          }}
        >
          paildの仕組みと特徴
        </PaildButton>
        <PaildButton
          outlined
          isAnchor
          onClick={() => {
            if (!issueSectionRef.current) return;
            anchorScroll(issueSectionRef.current.getBoundingClientRect().y);
          }}
        >
          カード発行について
        </PaildButton>
        <PaildButton
          outlined
          isAnchor
          onClick={() => {
            if (!functionSectionRef.current) return;
            anchorScroll(functionSectionRef.current.getBoundingClientRect().y);
          }}
        >
          機能一覧
        </PaildButton>
      </section>
      <FeatureSection ref={featureSectionRef} />
      <IssueSection ref={issueSectionRef} />
      <FunctionSection ref={functionSectionRef} />
    </Layout>
  );
};

const AnchorButtonSection = css({
  width: '100%',
  maxWidth: 1120,
  display: 'grid',
  placeItems: 'center',
  gap: 20,
  gridTemplateColumns: 'repeat(3, 1fr)',

  '@media(max-width: 1119px)': {
    padding: '0 17px',
  },

  '@media(max-width: 768px)': {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: 12,
  },
});

export default FeaturePage;
